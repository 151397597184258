import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { connectionIssueDark, connectionIssueLight } from 'images';
import { DarkModeContext } from 'utils/themeContext';
import { isMobile } from 'utils/media';
import Button from 'components/IR/Button';
import message from 'components/IR/ConnectionIssue/message';

export function ConnectionIssue({ onRefresh }) {
  const { darkMode } = useContext(DarkModeContext) || {};
  const image = darkMode ? connectionIssueDark : connectionIssueLight;
  return (
    <div
      className={classNames(
        'h-screen w-screen px-10 flex text-center justify-center items-center',
        darkMode ? 'bg-neutral-900' : 'bg-white',
      )}
    >
      <div className="flex flex-col items-center max-w-[28rem]">
        <img src={image} alt="Connection Issue" className="mb-11" />
        <p className={classNames('mb-1 font-medium', darkMode ? 'text-neutral-50' : 'text-slate-900')}>
          <FormattedMessage {...message.title} />
        </p>
        <p className={classNames('text-sm', darkMode ? 'text-neutral-300' : 'text-slate-700')}>
          <FormattedMessage {...message.message} />
        </p>
        <Button
          buttonClassname={classNames(
            'text-base font-semibold rounded-lg leading-normal py-2 px-4 bg-blue-600 mt-4 text-white bg-blue-600 hover:bg-blue-800',
            isMobile && 'w-full mt-6',
          )}
          size="md"
          data-testid="connectionIssueRefreshButton"
          onClick={onRefresh}
        >
          <FormattedMessage {...message.refresh} />
        </Button>
      </div>
    </div>
  );
}

export default ConnectionIssue;
