import React, { memo, useContext, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { find, get, groupBy } from 'lodash';
import { ThreeDots } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import { useEventLogger } from 'hooks/useEventLogger';
import { growthBookFeatureFlags } from 'utils/featureFlags';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { mixpanelEvents } from 'utils/mixpanelEvents';
import { DarkModeContext } from 'utils/themeContext';
import EvaluationBottomPanel from 'components/IR/EvaluationBottomPanel';
import reducer from 'containers/Evaluation/reducer';
import saga from 'containers/Evaluation/saga';
import {
  fetchEvaluationDetails,
  saveAction,
  updateCommentAction,
  updateRatingAction,
  updateRecommendationAction,
} from 'containers/Evaluation/actions';
import { makeEvaluation } from 'containers/Evaluation/selector';
import EvaluationForm from 'containers/Evaluation/EvaluationForm';
import EvaluationParameters from 'containers/Evaluation/EvaluationParameters';
import EvaluationError from 'images/EvaluationError.svg';
import NoFormParamsDark from 'images/instructions/Dark/NoFormAndParamsDark.svg';
import NoFormParamsLight from 'images/instructions/Light/NoFormAndParamsLight.svg';
import { selectMeetingDetailsState } from 'containers/InterviewRooms/selector';
import { exitParentFrame, getWindowObject } from 'containers/InterviewRooms/utils';
import { candidateDetailsState } from 'containers/IRRedirect/selectors';
import {
  POPUP_CANCEL_BUTTON,
  POPUP_CONTENT,
  POPUP_MODAL_TYPE,
  POPUP_SUCCESS_BUTTON,
  POPUP_TITLE,
} from 'containers/InterviewRooms/constants';
import PopupNew from 'components/PopupNew';
import ViewFormData from 'components/ViewFormData/ViewFormData';

export function Evaluation({
  getEvaluationDetails,
  state,
  onClose,
  save,
  updateRecommendation,
  updateRating,
  updateComment,
  currentParticipant,
  inMeeting,
}) {
  useInjectReducer({ key: 'evaluation', reducer });
  useInjectSaga({ key: 'evaluation', saga });
  const irFeedbackEnabled = useFeatureValue(growthBookFeatureFlags.INTERVIEW_ROOMS_FEEDBACK_ENABLED);
  const { recommendation, saveLoading, rating, step, evaluation, error, isLoading, evaluationComment, errorOnSubmit } =
    state || {};
  const qp = new URLSearchParams(useLocation().search);
  const liveAttendeeId = qp.get('pId');
  const meetingDetails = useSelector(selectMeetingDetailsState);
  const candidateDetails = useSelector(candidateDetailsState);
  const logEvent = useEventLogger();
  const navigate = useNavigate();
  const localParticipant = currentParticipant || getWindowObject('currentParticipant');
  const [leaveWithoutEvaluation, setLeaveWithoutEvaluation] = useState(true);
  const [submit, toggleSubmit] = useState(false);
  const [submissionError, setSubmissionError] = useState(errorOnSubmit);
  const asscId = get(meetingDetails, '0.assessment_section_candidate.id') || qp.get('assc_id');
  const assessment_section = get(step, 'assessment_section', {});
  const parameters =
    groupBy(get(assessment_section, 'section.section_parameters', []), (item) => get(item, 'parameter.category.id')) ||
    {};
  const paramsCount = Object.keys(parameters).length;
  const form = get(assessment_section, 'section.form', {});
  const { darkMode } = useContext(DarkModeContext);
  const NoFormParamsImage = darkMode ? NoFormParamsDark : NoFormParamsLight;

  const evaluated =
    !get(step, 'assessment_section.section.form.is_editable') &&
    get(
      find(
        get(step, 'panel_candidates', []),
        (panel) => get(panel, 'panel.user.id') === get(localParticipant, '0.user_id'),
      ),
      'evaluations.0.recommendation',
      'hold',
    ) !== 'hold';

  const evaluatedFormData = get(
    find(
      get(step, 'panel_candidates', []),
      (panel) => get(panel, 'panel.user.email') === get(localParticipant, '0.email'),
    ),
    'evaluations[0].form_instances[0].data',
    [],
  );

  const navigatePage = (path) => {
    navigate(path, { replace: true });
  };

  const handleOnExit = () => {
    if (irFeedbackEnabled) navigatePage(`/feedback?pId=${liveAttendeeId}`);
    else {
      exitParentFrame();
      navigatePage('/thankyou');
    }
  };

  const handleOnClose = (status) => {
    if (onClose) onClose(status);
    if (!inMeeting) {
      logEvent(get(mixpanelEvents, 'INTERVIEWER_EVALUATED_CANDIDATE_ON_MEETING_EXIT'));
      handleOnExit();
    }
  };

  const submitEvaluation = async (r) => {
    updateRecommendation(r);
    if (form?.custom_url && !evaluated) toggleSubmit(true);
    else await save({ recommendation: r, onClose: handleOnClose });
  };

  const handlePopupCancelClick = (e) => {
    setLeaveWithoutEvaluation(!leaveWithoutEvaluation);
    if (e !== 'noExit') {
      logEvent(get(mixpanelEvents, 'INTERVIEWER_DECLINED_EVALUATION_ON_MEETING_EXIT'));
      handleOnExit();
    }
  };

  useEffect(() => {
    if (asscId) getEvaluationDetails(asscId);
    else handleOnExit();
  }, []);

  useEffect(() => {
    setSubmissionError(errorOnSubmit);
  }, [errorOnSubmit]);

  return (
    <>
      {!inMeeting && (
        <PopupNew
          isOpen={leaveWithoutEvaluation}
          title={POPUP_TITLE.LEAVE_INTERVIEW_WITHOUT_EVALUATION}
          hasCancel
          content={POPUP_CONTENT.LEAVE_INTERVIEW_WITHOUT_EVALUATION}
          success={POPUP_SUCCESS_BUTTON.EVALUATE_NOW}
          cancel={POPUP_CANCEL_BUTTON.LATER}
          cancelClick={handlePopupCancelClick}
          onClose={() => setLeaveWithoutEvaluation(false)}
          type={POPUP_MODAL_TYPE.INTERVIEW_ROOMS}
          cancelBtnStyles="border border-solid dark:border-neutral-600 border-slate-600 dark:text-white text-slate-600 px-4 h-9"
          confirmBtnStyles="dark:bg-blue-600 bg-blue-500 text-white hover:bg-blue-600 ml-3 px-4 h-9"
        />
      )}
      <PopupNew
        isOpen={submissionError}
        title={POPUP_TITLE.EVALUATION_ERROR}
        titleLeadingIcon={faCircleExclamation}
        content={POPUP_CONTENT.EVALUATION_ERROR}
        success={POPUP_SUCCESS_BUTTON.OK}
        hasCancel={false}
        onClose={() => setSubmissionError(false)}
        cancelClick={() => setSubmissionError(false)}
        type={POPUP_MODAL_TYPE.INTERVIEW_ROOMS}
        confirmBtnStyles="dark:bg-blue-600 bg-blue-500 text-white hover:bg-blue-600 ml-3 px-4 h-9"
      />
      <div
        className={classNames(
          'border dark:border-neutral-700 border-gray-200 rounded-lg dark:bg-neutral-800 bg-white flex flex-col h-full w-full',
          !inMeeting && 'h-screen w-screen',
        )}
        data-testid="EvaluationScreen"
      >
        <div
          className={classNames(
            'flex justify-center items-center px-4 border dark:border-neutral-700 border-slate-300 border-t-0 border-l-0 border-r-0',
            inMeeting && 'justify-between',
          )}
        >
          <div></div>
          <h1 className="dark:text-neutral-50 text-slate-900 rounded-lg text-xl font-semibold p-2 ">Evaluation</h1>
          {inMeeting && (
            <FontAwesomeIcon
              icon={faXmark}
              className="dark:text-neutral-50 text-slate-900 cursor-pointer"
              size="lg"
              data-testid="closeEvaluation"
              onClick={onClose}
            />
          )}
        </div>
        {evaluated && (
          <div className="h-full overflow-y-scroll no-scrollbar">
            <div className="px-10 py-6 dark:bg-neutral-50 bg-slate-900 ">
              <div className="px-4 py-3 flex rounded border dark:border-blue-500 border-blue-400 mb-5">
                <FontAwesomeIcon
                  icon={faClipboard}
                  className="dark:text-neutral-700 text-slate-300 cursor-pointer mr-6 h-14 w-8"
                  size="lg"
                  onClick={onClose}
                />
                <div>
                  <h1 className="font-bold text-lg dark:text-neutral-900 text-slate-50">Evaluation complete</h1>
                  <p className="dark:text-neutral-700 text-slate-300">
                    This form has already been submitted.Re-submission is not a option
                  </p>
                </div>
              </div>
              <ViewFormData formData={evaluatedFormData} />
            </div>
          </div>
        )}
        {!evaluated && (
          <div className="flex overflow-scroll no-scrollbar h-full justify-center items-center">
            {isLoading && (
              <div data-testid="evaluation-loader" className="h-full w-full flex justify-center items-center ">
                <ThreeDots color="#2563eb" height={150} width={80} />
              </div>
            )}
            {!form?.custom_url && !paramsCount && !isLoading && (
              <div className="w-screen flex justify-center dark:text-neutral-300 text-slate-600 items-center -mt-10">
                <img src={NoFormParamsImage} alt="No Evaluation form and parameters" />
              </div>
            )}
            {error && !isLoading && (
              <div className="w-screen flex justify-center items-center -mt-10">
                <img src={EvaluationError} alt="Something went wrong" />
              </div>
            )}
            {!evaluated && !isLoading && paramsCount > 0 && (
              <div
                className="overflow-scroll no-scrollbar h-full w-full border dark:border-neutral-700 border-slate-300"
                data-testid="evaluationParams"
              >
                <EvaluationParameters
                  evaluation={evaluation}
                  parameters={parameters}
                  assessment_section={assessment_section}
                  updateRating={updateRating}
                  rating={rating}
                />
              </div>
            )}
            {!evaluated && !isLoading && form?.custom_url && (
              <div
                className="overflow-scroll no-scrollbar h-full w-full border dark:border-neutral-700 border-slate-300"
                data-testid="evaluationForm"
              >
                <EvaluationForm
                  form={form}
                  step={step}
                  submit={submit}
                  toggleSubmit={toggleSubmit}
                  save={(d, files) => {
                    if (d || files)
                      save({
                        formData: d,
                        files,
                        onClose: handleOnClose,
                      });
                  }}
                />
              </div>
            )}
          </div>
        )}
        <EvaluationBottomPanel
          evaluated={evaluated}
          candidateDetails={candidateDetails}
          submitEvaluation={submitEvaluation}
          recommendation={recommendation}
          saveLoading={saveLoading}
          updateComment={updateComment}
          evaluationComment={evaluationComment}
        />
      </div>
    </>
  );
}

Evaluation.propTypes = {
  state: PropTypes.object,
  getEvaluationDetails: PropTypes.func,
  updateRating: PropTypes.func,
  updateRecommendation: PropTypes.func,
  updateComment: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  state: makeEvaluation(),
});

export function mapDispatchToProps(dispatch) {
  return {
    getEvaluationDetails: (id) => dispatch(fetchEvaluationDetails(id)),
    save: ({ recommendation, formData, files, onClose }) =>
      dispatch(saveAction({ recommendation, formData, files, onClose })),
    updateRating: (rating) => dispatch(updateRatingAction(rating)),
    updateRecommendation: (recommendation) => dispatch(updateRecommendationAction(recommendation)),
    updateComment: (evaluationComment) => dispatch(updateCommentAction(evaluationComment)),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(Evaluation);
